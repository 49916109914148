var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('div',[(_vm.isRegister)?_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-container"},[_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.regMsg)+" ")])]),_c('hr'),_c('button',{staticClass:"closeBtn",on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")])])]):_vm._e()]),_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticClass:"login_logo_img",attrs:{"fluid":"","src":_vm.loginUrl,"alt":"Login V2"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center justify-content-center p-5 flex-column",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5 mb-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Vector"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t("Welcome to InteGreat"))+"! "),_c('div',{staticStyle:{"width":"50%"}},[_c('locale',{staticStyle:{"list-style":"none"}})],1)]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Please sign-up"))+" ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"custom_fieldset"},[_c('b-form-group',{attrs:{"label":"CVR","label-for":"CVR"}},[_c('validation-provider',{attrs:{"name":"CVR","rules":"required|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"CVR","name":"CVR","state":errors.length > 0 ? false : null},on:{"keyup":_vm.getCVR},model:{value:(_vm.CVR),callback:function ($$v) {_vm.CVR=$$v},expression:"CVR"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('cvr-name'),"label-for":"cvr-name"}},[_c('b-form-input',{attrs:{"id":"cvr-name","name":"cvr-name","placeholder":"johndoe","readonly":""},model:{value:(_vm.cvrdata.name),callback:function ($$v) {_vm.$set(_vm.cvrdata, "name", $$v)},expression:"cvrdata.name"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('cvr-adr'),"label-for":"cvr-adr"}},[_c('b-form-textarea',{staticClass:"mb-2 mb-lg-0",attrs:{"readonly":""},model:{value:(_vm.cvrdata.address),callback:function ($$v) {_vm.$set(_vm.cvrdata, "address", $$v)},expression:"cvrdata.address"}})],1),_c('b-row',[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cvr-zip`'),"label-for":"cvr-zip"}},[_c('b-form-input',{attrs:{"id":"cvr-zip","name":"cvr-zip","readonly":""},model:{value:(_vm.cvrdata.zipcode),callback:function ($$v) {_vm.$set(_vm.cvrdata, "zipcode", $$v)},expression:"cvrdata.zipcode"}})],1)],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cvr-city'),"label-for":"cvr-city"}},[_c('b-form-input',{attrs:{"id":"cvr-city","name":"cvr-city","readonly":""},model:{value:(_vm.cvrdata.city),callback:function ($$v) {_vm.$set(_vm.cvrdata, "city", $$v)},expression:"cvrdata.city"}})],1)],1)],1),_c('span',{staticClass:"fieldset_label"},[_vm._v(_vm._s(_vm.$t("Company Info")))])],1),_c('br'),_c('div',{staticClass:"custom_fieldset"},[_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Name","name":"Name","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.userdata.name),callback:function ($$v) {_vm.$set(_vm.userdata, "name", $$v)},expression:"userdata.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Email","name":"Email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.userdata.email),callback:function ($$v) {_vm.$set(_vm.userdata, "email", $$v)},expression:"userdata.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Telephone'),"label-for":"Telephone"}},[_c('validation-provider',{attrs:{"name":"telephone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Telephone","name":"Telephone","state":errors.length > 0 ? false : null},model:{value:(_vm.userdata.phone),callback:function ($$v) {_vm.$set(_vm.userdata, "phone", $$v)},expression:"userdata.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('span',{staticClass:"fieldset_label"},[_vm._v(_vm._s(_vm.$t("Personal Info")))])],1),_c('br'),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":_vm.registrationForm}},[_vm._v(" "+_vm._s(_vm.$t("Next"))+" "),_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"ArrowRightIcon"}})],1)],1)],1),_c('p',{staticClass:"text-center mt-2 custom-text"},[_c('span',[_vm._v(_vm._s(_vm.$t("Already have an account"))+"?")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Sign in instead")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }